<template>
  <v-expansion-panels>
    <v-expansion-panel v-for="(item, i) in accordion_cell" :key="i">
      <v-expansion-panel-header>
        <v-row align="center" justify="center">
          <v-col cols="4" class="pt-7">
            <p
              class="text-primary text-uppercase font-weight-bold"
              style="font-size:12px;"
            >
              <span class="font-weight-bold text-uppercase dark">
                {{ item.name }}</span
              >
            </p>
          </v-col>
          <v-col cols="4" class="pa-0">
            <v-checkbox
              :disabled="true"
              label="OK"
              :input-value="!item.checkbox_pruefen"
              @click.native.stop=""
            ></v-checkbox>
          </v-col>
          <v-col cols="1" class="pa-0 mr-40">
            <v-checkbox
              v-model="item.checkbox_pruefen"
              label="Prüfen"
              :input-value="item.checkbox_pruefen"
              :disabled="item.checkbox_flecken ||
              item.checkbox_legung ||
              item.checkbox_defekte ||
              item.checkbox_menge ||
              item.checkbox_anderes"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-col cols="4" v-if="item.checkbox_flecken != null" class="ml-15">
            <v-checkbox
              v-model="item.checkbox_flecken"
              label="Flecken"
              @change="update()"
            ></v-checkbox>
            <v-checkbox
              v-model="item.checkbox_legung"
              label="Legung"
              @change="update()"
            ></v-checkbox>
            <v-checkbox
              v-model="item.checkbox_defekte"
              label="Defekte"
              @change="update()"
            ></v-checkbox>
            <v-checkbox
              v-model="item.checkbox_menge"
              label="Menge"
              @change="update()"
            ></v-checkbox>
            <v-checkbox
              v-model="item.checkbox_anderes"
              label="Anderes"
              @change="update()"
            ></v-checkbox>
          </v-col>
          <v-col cols="8" v-if="item.checkbox_anderes" class="ml-15">
            <v-textarea
              v-model="item.notiz"
              name="input-7-1"
              filled
              label="Hinterlegen Sie hier Ihre Notizen"
              @input="update()"
            ></v-textarea>
            <v-col cols="12">
              <v-file-input
                v-model="item.images"
                label="Bild hinzufügen - JPEG/PNG (optional)"
                prepend-icon="mdi-camera"
                outlined
                counter="5"
                small-chips
                dense
                multiple
                show-size
                accept="image/png, image/gif, image/jpeg"
                @change="uploadImages(item.images, i)"
                @click:clear="clearButtonClicked(i)"
              ></v-file-input>
              <v-progress-circular
                v-if="loading_active"
                indeterminate
                color="green"
              ></v-progress-circular>
              <div v-if="upload_failure">
                <div @click="uploadImages(item.images, i)">
                  <v-progress-circular :value="40"></v-progress-circular> Erneut
                  hochladen
                </div>
              </div>
            </v-col>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
  name: "ExpansionItem",
  props: {
    accordion_data: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default() {
        return [];
      }
    },
    protokoll_id: String
  },
  data: function() {
    return {
      accordion_cell: null,
      loading_active: false,
      upload_failure: false
    };
  },
  created() {
    this.accordion_cell = this.accordion_data;
  },
  methods: {
    update() {
      this.accordion_cell.forEach((item, index) => {
        // Überprüfen, ob Bilder vorhanden sind und ob die Größe existiert
        const hasImage = item.images && item.images.length > 0;
        if (
            item.checkbox_flecken ||
            item.checkbox_legung ||
            item.checkbox_defekte ||
            item.checkbox_menge ||
            (item.checkbox_anderes && hasImage) || // Nur, wenn das erste Bild eine Größe hat
            (item.checkbox_anderes && item.notiz && item.notiz.trim() !== '') // Überprüfe, ob eine Notiz vorhanden ist
        ) {
          this.$set(this.accordion_cell, index, {
            ...item,
            checkbox_pruefen: true, // Setze nur das betroffene Item auf "Prüfen"
          });
        } else {
          this.$set(this.accordion_cell, index, {
            ...item,
            checkbox_pruefen: false, // Setze das betroffene Item auf "Nicht prüfen", falls keine Änderungen vorliegen
          });
        }
      });

      this.$emit("update", {
        accordion_data: this.accordion_cell
      });
    },
    uploadImages(item_bild, index) {
      if (this.accordion_cell[index].images.length) {
        this.loading_active = true;
        this.upload_failure = false;
        let formData = new FormData();

        formData.append("protokoll_id", JSON.stringify(this.protokoll_id));
        // files test
        for (let file of item_bild) {
          formData.append("file[]", file, file.name);
        }

        // eslint-disable-next-line no-unused-vars
        let protokoll_id = this.protokoll_id;
        ApiService.post("protokoll/uploadimages", formData)
          .then(({ data }) => {
            this.accordion_cell[index].images_names = data.img_names;
            this.loading_active = false;
            this.upload_failure = false;
            this.update();
          })
          .catch(({ response }) => {
            this.loading_active = false;
            this.upload_failure = true;
            if (response.status === 401) {
              if (response.status === 401) {
                // this.$store
                //     .dispatch(LOGOUT)
                //     .then(() => this.$router.push({ name: "login" }));
              }
            }
          });
      }
    },
    clearButtonClicked(index) {
      this.upload_failure = false;
      this.accordion_cell[index].images = [];
      this.accordion_cell[index].images_names = [];
      this.update()
    }
  }
};
</script>

<style scoped></style>
